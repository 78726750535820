body {
  font-family: 'Nunito', sans-serif;
}

.site-layout .site-layout-background {
  background: #fff;
}

.container {
  padding: 20px 15px;
}

.test {
  /* background: url('./assets//background.jpg'); */
}
