html,
body {
  height: 100vh;
  width: 100%;
  position: relative;
}

.App {
  width: 100%;
  height: 100%;
  position: relative;
  transition: background 1.5s linear;
}

.AppWrapper {
  width: 100%;
  height: 100%;
}

#root {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: auto;
}

*,
::after,
::before {
  box-sizing: inherit;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
